import { ToastrService } from 'ngx-toastr';

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { APiService } from '../../service/api.service';
import { Router } from '@angular/router';
import { NotifierService } from "angular-notifier";
import { CookieService } from 'ngx-cookie-service';
// import { LocalStorage } from '@ngx-pwa/local-storage';
import { LoadingProvider } from '../../service/loading-provider';
import { SessionData } from '../../service/seisson-data';
import { AuthenticationService } from 'src/app/service/AuthenticationService.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public sessionData = SessionData;
  typeingemail: any;
  public PasswordType = 'password';
  private readonly notifier: NotifierService;
  auth2: any;
  @ViewChild('loginRef', { static: true }) loginElement: ElementRef;
  constructor(
    private ApiService: APiService,
    private router: Router,
    private notifierService: NotifierService,
    private toastr: ToastrService,
    private LoadingProvider: LoadingProvider,
    private authenticationService: AuthenticationService
    // private cookieService: CookieService
  ) {
    this.notifier = notifierService;

  }

  ngOnInit(): void {

  }

  LogInForm = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.email]),
    password: new FormControl('',
      Validators.required)
  })

  ChangePasswordStyle() {
    if (this.PasswordType === 'password') {
      this.PasswordType = 'text';
    } else if (this.PasswordType === 'text') {
      this.PasswordType = 'password';
    }
  }

  checkemail(event) {
    this.typeingemail = true
    if (!/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(event.target.value)) {

      event.target.classList.add('is-invalid');
    }
    else {

      event.target.classList.remove('is-invalid');
    }
  }
  onSubmit(formData) {
    // //console.log("jjkkkkkkkkk");

    // this.toastr.success("تم  تسجيل الدخول بنجاح أهلا بك", "");
    // //console.log("nnnnn");
    // this.toastr.error('everything is broken', 'Major Error', {
    //   timeOut: 3000,
    // });
    // //console.log("jjjjjjj");
    if (formData.valid) {
      this.LoadingProvider.ViewHttpServiceLoadingDiv()
      this.ApiService.logIn(formData.value).subscribe(
        res => {
          // //console.log(res);
          if (res["data"] != null) {
            SessionData.SaveDataInSessionByLoginAndValidateSessionAndCheckNumber(res["data"]);
            // this.notifier.notify("success", "Welcome, " + res.data.username);
            this.toastr.success(res["message"]);

            // this.cookieService.set('lang', res.data.lang)
            this.router.navigate(['/HomeComponent']).then(() => {
              window.location.reload();
            });
            this.LoadingProvider.CloseHttpServiceLoading()
          }
          else {
            // this.notifier.notify("warning", "خطأ في البريد الأكتروني أو كلمة السر");
            this.toastr.error(res["message"]);

            this.LoadingProvider.CloseHttpServiceLoading()
          }
        })
    }
    else {
      // this.notifier.notify("warning", "من فضلك قم بملئ البيانات بشكل صحيح");
      if (localStorage.getItem('lang') == 'ar') {
        this.toastr.error("من فضلك قم بملئ البيانات بشكل صحيح");
      }
      else {
        this.toastr.error("please fill right data");
      }

    }
  }

  login() {
    this.authenticationService.doFacebookLogin();
  }
  loginGoogle() {

    this.authenticationService.doGoogleLogin_page();
  }
}
