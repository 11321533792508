import { SessionData } from './../../service/seisson-data';
import { APiService } from './../../service/api.service';
import { LoadingProvider } from './../../service/loading-provider';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  Newsproducts = [

  ]
  products = [

  ]
  new_data = [];
  ads = new Array();
  adsButton = new Array();
  SliderImage: any[];
  public sessionData = SessionData;
  constructor(
    private ApiService: APiService,
    private LoadingProvider: LoadingProvider
  ) { }

  ngOnInit(): void {
    // this.SliderImage = [
    //   'assets/images/headerBG.png',
    //   'assets/images/headerBG.png',
    //   'assets/images/headerBG.png'
    // ]
    this.LoadingProvider.ViewHttpServiceLoadingDiv()
    this.get_Home_page()
  }
  get_Home_page() {
    this.ApiService.Home_page().subscribe(
      res => {
        let resources = res["data"];
        //console.log(resources.sliders);

        this.SliderImage = resources.sliders;
        this.products = resources.best_seller;
        this.Newsproducts = resources.new_item;
        this.new_data = resources.News;

        this.ads = [];
        //console.log(resources.ads.length);

        if (resources.ads.length == 1) {
          this.ads.push(resources.ads[0]);
        }
        else if (resources.ads.length == 2) {
          this.ads.push(resources.ads[0]);
          this.ads.push(resources.ads[1]);
        }
        else if (resources.ads.length == 3) {
          this.ads.push(resources.ads[0]);
          this.ads.push(resources.ads[1]);
          this.adsButton.push(resources.ads[2]);
        }
        else if (resources.ads.length == 4) {
          this.ads.push(resources.ads[0]);
          this.ads.push(resources.ads[1]);
          this.adsButton.push(resources.ads[2]);
          this.adsButton.push(resources.ads[3]);
        }
        else if (resources.ads.length == 5) {
          this.ads.push(resources.ads[0]);
          this.ads.push(resources.ads[1]);
          this.adsButton.push(resources.ads[2]);
          this.adsButton.push(resources.ads[3]);
          this.adsButton.push(resources.ads[4]);
        }
        let message: any = res["message"];
        this.LoadingProvider.CloseHttpServiceLoading()
      });
    // this.LoadingProvider.CloseHttpServiceLoading()
  }
}
