import { LoadingProvider } from './../../service/loading-provider';
import { NotifierService } from 'angular-notifier';
import { Router } from '@angular/router';
import { APiService } from './../../service/api.service';
import { Component, OnInit, HostListener } from '@angular/core';
import { NgbRatingConfig, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SessionData } from '../../service/seisson-data';
// import { LanguageService } from 'src/app/service/LanguageService.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [NgbRatingConfig]
})
export class HeaderComponent implements OnInit {
  SliderImage: any[];
  // ContactUs = new Array();
  constructor(
    private ApiService: APiService,
    private router: Router,
    private notifierService: NotifierService,
    // private languageService: LanguageService,
    private translate: TranslateService,
    private LoadingProvider: LoadingProvider

  ) { }
  cuurentLang: any;
  public sessionData = SessionData;
  subscriptions: Subscription[] = [];
  ngOnInit() {
    // let langSubscription = this.languageService.currentLanguage.subscribe((lang) => {
    //   let httpSubscription = this.httpService.getPageDataBasedOnLanguage().subscribe((response) => {
    //     this.data = response;
    //   })
    //   this.subscriptions.push(httpSubscription);
    // })
    // this.subscriptions.push(langSubscription);
    // //console.log(this.sessionData.UserIsLoggedIn);
    if (localStorage.getItem('lang') == null || localStorage.getItem('lang') == 'ar') {
      this.cuurentLang = 'العربيه'
    }
    else {
      this.cuurentLang = 'english'
    }
    this.SliderImage = [
      'assets/images/headerBG.png',
      'assets/images/headerBG.png',
      'assets/images/headerBG.png'
    ]
    // this.LoadingProvider.ViewHttpServiceLoadingDiv()
    // this.get_aboutus()
  }
  changeLang(lang: any) {
    this.translate.use(lang);
    if (lang == 'ar') {
      this.cuurentLang = 'العربيه';
      this.translate.use(lang);
      console.log(this.cuurentLang);
      localStorage.setItem('lang', lang);
      window.location.reload();
    }
    else {
      this.cuurentLang = 'english';
      this.translate.use(lang)
      console.log(this.cuurentLang);

      localStorage.setItem('lang', lang);
      window.location.reload();
    }
  }
  // public changeLanguage(newLanguage) {
  //   this.currentLanguage.next("en");
  // }
  logout() {
    this.LoadingProvider.ViewHttpServiceLoadingDiv()
    this.ApiService.LogOut().subscribe(
      res => {
        let resources: any[] = res["data"];
        // //console.log(resources);
        SessionData.ClearDataInSessionByLogOut();

        this.router.navigate(['/HomeComponent']).then(() => {
          window.location.reload();
        });
        // this.ContactUs.push(resources);
        // let message: any = res["message"];
        this.LoadingProvider.CloseHttpServiceLoading();
      });
  }
  // get_aboutus() {
  //   this.ContactUs = [];
  //   this.ApiService.get_ContactUs().subscribe(
  //     res => {
  //       let resources: any[] = res["data"];
  //       // //console.log(res);

  //       this.ContactUs.push(resources);
  //       let message: any = res["message"];
  //     });
  //   this.LoadingProvider.CloseHttpServiceLoading()
  // }
  // @HostListener('window: scroll', ['$event'])
  // onwidowsScroll(e) {
  //   let element = document.querySelector('.navbar-area');
  //   let element1 = document.querySelector('.black-logo');
  //   let element2 = document.querySelector('.white-logo');
  //   if (window.pageYOffset > element.clientHeight) {
  //     element.classList.add('navbar-inverse');
  //   } else {
  //     element.classList.remove('navbar-inverse');
  //   }
  // }
  closeNav() {
    // let element = document.querySelector('.navbar-collapse');
    let element = document.querySelector('.sidenav');

    element.classList.remove('ShowSide')
  }
  openNav() {
    let element = document.querySelector('.sidenav');
    element.classList.add('ShowSide')
    // document.getElementById("mySidenav").style.width = "250px";
  }
}
