import { APiService } from './../../service/api.service';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { LoadingProvider } from './../../service/loading-provider';
import { NgWizardService } from 'ng-wizard';
import { SessionData } from './../../service/seisson-data';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {

  private readonly notifier: NotifierService;
  ContactUs = new Array();
  constructor(
    private ApiService: APiService,
    private router: Router,
    private notifierService: NotifierService,
    private LoadingProvider: LoadingProvider,
    private ngWizardService: NgWizardService
  ) {
    this.notifier = notifierService;
  }
  public sessionData = SessionData;
  Orders: any;
  CurrentPage: any;
  SingleOrder: any;
  ngOnInit(): void {
    this.LoadingProvider.ViewHttpServiceLoadingDiv()
    this.get_order();
    this.CurrentPage = 'list'
  }
  get_order() {
    // this.ContactUs = [];
    this.ApiService.Get_Orders().subscribe(
      res => {
        let resources: any[] = res["data"];
        this.Orders = resources;
        //console.log(this.Orders);
        let message: any = res["message"];
        this.LoadingProvider.CloseHttpServiceLoading()
      });
    // this.LoadingProvider.CloseHttpServiceLoading()
  }
  getSingleOrder(id) {
    this.CurrentPage = "singlePage"
    this.LoadingProvider.ViewHttpServiceLoadingDiv();
    this.ApiService.SinglOrder(id).subscribe(
      res => {
        let resources: any[] = res["data"].products;
        this.SingleOrder = resources;
        //console.log(this.SingleOrder);
        let message: any = res["message"];
        this.LoadingProvider.CloseHttpServiceLoading()
      });
  }
  ShowPage(page) {
    this.CurrentPage = page
  }
}
