import { LoadingProvider } from './../../service/loading-provider';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { APiService } from './../../service/api.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {

  cartProduct: any;
  constructor(
    private ApiService: APiService,
    private router: Router,
    private toastr: ToastrService,
    private LoadingProvider: LoadingProvider
  ) { }

  ngOnInit(): void {
    this.get_aboutus()
  }

  get_aboutus() {
    this.LoadingProvider.ViewHttpServiceLoadingDiv()
    this.ApiService.Brands().subscribe(
      res => {
        let resources: any[] = res["data"];
        this.cartProduct = resources
        this.LoadingProvider.CloseHttpServiceLoading()
      });
  }
}
