import { LoadingProvider } from './../../service/loading-provider';
import { Component, OnInit } from '@angular/core';
import { APiService } from '../../service/api.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

  new_data: any;

  constructor(
    private ApiService: APiService,
    private LoadingProvider: LoadingProvider
  ) { }

  ngOnInit(): void {
    this.LoadingProvider.ViewHttpServiceLoadingDiv()
    this.get_news()
  }

  get_news() {


    this.ApiService.get_news().subscribe(
      res => {
        let resources: any[] = res["data"];
        // //console.log(res);

        this.new_data = resources;
        let message: any = res["message"];
        this.LoadingProvider.CloseHttpServiceLoading()

      });

  }
  readMore(id) {
    this.ApiService.get_one_news(id).subscribe(
      res => {
        let resources: any[] = res["data"];
        // //console.log(res);

        this.new_data = resources;
        let message: any = res["message"];
      });
  }
}
