import { ToastrService } from 'ngx-toastr';
import { SessionData } from './../../service/seisson-data';
import { LoadingProvider } from './../../service/loading-provider';
import { NotifierService } from 'angular-notifier';
import { Router } from '@angular/router';
import { APiService } from './../../service/api.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  // ContactUs = new Array();
  public sessionData = SessionData;
  Email: string = "";
  // @ViewChild() Email: ElementRef
  // @ViewChild('Email') redel:ElementRef;
  constructor(
    private ApiService: APiService,
    private router: Router,
    private notifierService: NotifierService,
    private toastr: ToastrService,
    private LoadingProvider: LoadingProvider
  ) { }

  ngOnInit(): void {
    // this.LoadingProvider.ViewHttpServiceLoadingDiv()
    // this.get_aboutus()
  }
  // get_aboutus() {
  //   this.ContactUs = [];
  //   this.ApiService.get_ContactUs().subscribe(
  //     res => {
  //       let resources: any[] = res["data"];
  //       // //console.log(res);

  //       this.ContactUs.push(resources);
  //       let message: any = res["message"];
  //     });
  //     this.LoadingProvider.CloseHttpServiceLoading()
  // }
  subscribe(Email) {
    this.LoadingProvider.ViewHttpServiceLoadingDiv()
    let email = (<HTMLInputElement>document.getElementById('EmailValue')).value;
    //console.log(Email);
    if (email != '') {
      this.ApiService.Home_subscribe(email).subscribe(
        res => {
          let resources: any[] = res["data"];
          this.toastr.success(res["message"]);
          let message: any = res["message"];
          this.LoadingProvider.CloseHttpServiceLoading()
          // this.Email = ""
        });
      // this.Email = ""
      // this.LoadingProvider.CloseHttpServiceLoading();
    }
    else {
      if (localStorage.getItem('lang') == 'ar') {
        this.toastr.error("من فضلك قم بملئ البيانات بشكل صحيح");
      }
      else {
        this.toastr.error("please fill right data");
      }
      this.LoadingProvider.CloseHttpServiceLoading()
    }
    // this.Email = ""
  }
}
