import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { APiService } from '../../service/api.service';
import { Router } from '@angular/router';
import { NotifierService } from "angular-notifier";
import { CookieService } from 'ngx-cookie-service';
// import { LocalStorage } from '@ngx-pwa/local-storage';
import { LoadingProvider } from '../../service/loading-provider';
import { SessionData } from '../../service/seisson-data';

@Component({
  selector: 'app-write-code',
  templateUrl: './write-code.component.html',
  styleUrls: ['./write-code.component.scss']
})
export class WriteCodeComponent implements OnInit {

  typeingemail: any;
  PasswordType = 'password';
  typeingFirstName: boolean;
  LastName: boolean;
  imgSrc: string = "assets/images/image-placeholder.jpg";
  selectedImage: any = null;
  private readonly notifier: NotifierService;

  constructor(
    private ApiService: APiService,
    private router: Router,
    private notifierService: NotifierService,
    private LoadingProvider: LoadingProvider,
    private toastr: ToastrService,
    // private cookieService: CookieService
  ) {
    this.notifier = notifierService;
  }

  ngOnInit(): void {
  }

  LogInForm = new FormGroup({
    code: new FormControl('',
      Validators.required)
  })

  ChangePasswordStyle() {
    if (this.PasswordType === 'password') {
      this.PasswordType = 'text';
    } else if (this.PasswordType === 'text') {
      this.PasswordType = 'password';
    }
  }

  checkemail(event) {
    this.typeingemail = true
    if (!/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(event.target.value)) {

      event.target.classList.add('is-invalid');
    }
    else {

      event.target.classList.remove('is-invalid');
    }
  }
  onSubmit(formData) {
    if (formData.valid) {
      this.LoadingProvider.ViewHttpServiceLoadingDiv()
      this.ApiService.check_code(formData.value).subscribe(
        res => {
          // //console.log(res);
          if (res.status != 0) {
            SessionData.SaveDataInSession(res["data"]);
            this.toastr.success(res["message"]);
            // this.cookieService.set('lang', res.data.lang)
            this.router.navigate(['/ResetPasswordComponent']).then(() => {
              window.location.reload();
            });
            this.LoadingProvider.CloseHttpServiceLoading()
          }
          else {
            this.toastr.error(res["message"]);
            this.LoadingProvider.CloseHttpServiceLoading()
          }
        })
    }
    else {
      if (localStorage.getItem('lang') == 'ar') {
        this.toastr.error("من فضلك قم بملئ البيانات بشكل صحيح");
      }
      else {
        this.toastr.error("please fill right data");
      }
    }
  }

}
