import { LoadingProvider } from './../../service/loading-provider';
// import { NotifierService } from 'angular-notifier';
import { Router } from '@angular/router';
import { APiService } from './../../service/api.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  //
  typeingemail: any;
  public PasswordType = 'password';
  // private readonly notifier: NotifierService;

  constructor(
    private ApiService: APiService,
    private router: Router,
    // private notifierService: NotifierService,
    private toastr: ToastrService,
    private LoadingProvider: LoadingProvider
    // private cookieService: CookieService
  ) {
    // this.notifier = notifierService;
  }


  ngOnInit(): void {
  }

  LogInForm = new FormGroup({
    passwordConfirmation: new FormControl('',
      Validators.required),
    password: new FormControl('',
      Validators.required)
  })

  ChangePasswordStyle() {
    if (this.PasswordType === 'password') {
      this.PasswordType = 'text';
    } else if (this.PasswordType === 'text') {
      this.PasswordType = 'password';
    }
  }

  checkemail(event) {
    this.typeingemail = true
    if (!/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(event.target.value)) {

      event.target.classList.add('is-invalid');
    }
    else {

      event.target.classList.remove('is-invalid');
    }
  }
  onSubmit(formData) {
    if (formData.value.password == formData.value.passwordConfirmation) {
      if (formData.valid) {
        this.LoadingProvider.ViewHttpServiceLoadingDiv()
        this.ApiService.reset_password(formData.value).subscribe(
          res => {
            // //console.log(res);
            if (res.status != 0) {
              // SessionData.SaveDataInSessionByLoginAndValidateSessionAndCheckNumber(res["data"]);
              // this.toastr.success(" تم تغيير كلمة السر قم بتسجيل الدخول");
              this.toastr.success(res["message"]);
              // this.cookieService.set('lang', res.data.lang)
              this.router.navigate(['/LoginComponent']).then(() => {
                // window.location.reload();
              });
              this.LoadingProvider.CloseHttpServiceLoading()
            }
            else {
              // this.toastr.error("خطأ في  الكود   ");
              this.toastr.error(res["message"]);

              this.LoadingProvider.CloseHttpServiceLoading()
            }
          })
      }
      else {
        if (localStorage.getItem('lang') == 'ar') {
          this.toastr.error("من فضلك قم بملئ البيانات بشكل صحيح");
        }
        else {
          this.toastr.error("please fill right data");
        }
      }
    }
    else {
      if (localStorage.getItem('lang') == 'ar') {
        this.toastr.error("كلمة السر غير متطابقه");
      }
      else {
        this.toastr.error("password not matching");
      }
    }
  }
}
