import { ToastrService } from 'ngx-toastr';
import { NotifierService } from 'angular-notifier';
import { SessionData } from './../../service/seisson-data';
import { LoadingProvider } from './../../service/loading-provider';
import { APiService } from './../../service/api.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { Validators, FormControl, FormGroup } from '@angular/forms';
// import { RatingModule } from 'ngx-bootstrap/rating';
@Component({
  selector: 'app-product-detailes',
  templateUrl: './product-detailes.component.html',
  styleUrls: ['./product-detailes.component.scss']
})
export class ProductDetailesComponent implements OnInit {

  mainid: any;
  product_datiles = new Array();
  public sessionData = SessionData;
  private readonly notifier: NotifierService;
  quantity_pro = 1
  color_pro = null;
  size_pro = null
  constructor(
    private ApiService: APiService,
    private route: ActivatedRoute,
    private router: Router,
    private notifierService: NotifierService,
    private toastr: ToastrService,
    private LoadingProvider: LoadingProvider
  ) {
    this.notifier = notifierService;
  }
  LogInForm = new FormGroup({
    comment: new FormControl('', [
      Validators.required]),
  })
  comments;
  rate = [
    { id: 1, 'isSelected': false },
    { id: 2, 'isSelected': false },
    { id: 3, 'isSelected': false },
    { id: 4, 'isSelected': false },
    { id: 5, 'isSelected': false }
  ];
  ratevalue = 0;
  ngOnInit(): void {

    this.mainid = +this.route.snapshot.paramMap.get('id');
    this.get_Product_comment(this.mainid)
    this.get_Dtiales_products(this.mainid);
  }
  getRate(rates) {
    this.ratevalue = rates
    //console.log(this.rate);
    for (let i = 0; i < 5; i++) {
      this.rate[i].isSelected = false;
    }
    for (let i = 0; i < rates; i++) {
      this.rate[i].isSelected = true;
    }
  }
  get_Product_comment(id) {
    // this.product_datiles = []
    // this.LoadingProvider.ViewHttpServiceLoadingDiv()
    this.ApiService.get_Produccommit(id).subscribe(
      res => {
        let resources: any[] = res["data"];
        // //console.log(res);

        this.comments = resources;
        // this.LoadingProvider.CloseHttpServiceLoading()
        // let message: any = res["message"];
        // this.LoadingProvider.CloseHttpServiceLoading()
      });

  }
  get_Dtiales_products(id) {
    this.product_datiles = []
    this.LoadingProvider.ViewHttpServiceLoadingDiv()
    this.ApiService.get_Products_dtailes(id).subscribe(
      res => {
        let resources: any[] = res["data"];
        // //console.log(res);

        this.product_datiles.push(resources);
        // let message: any = res["message"];
        this.LoadingProvider.CloseHttpServiceLoading()
      });

  }
  // $('.star_rating span').on('click', function(){
  //   var onStar = parseFloat($(this).data('value'), 10); // The star currently selected
  //   var stars = $(this).parent().children('.star_rating span');
  //   for (var i = 0; i < stars.length; i++) {
  //     $(stars[i]).removeClass('selected');
  //   }
  //   for (i = 0; i < onStar; i++) {
  //     $(stars[i]).addClass('selected');
  //   }
  // });
  onSubmit(formData) {
    console.log(formData);

    if (formData.status != "INVALID" || formData.value.comment != "" || this.ratevalue != 0) {
      this.LoadingProvider.ViewHttpServiceLoadingDiv()
      this.ApiService.add_Produccommit(this.mainid, formData.value).subscribe(
        res => {
          // //console.log(res);
          if (res["data"] != null) {
            this.ApiService.add_ProducRate(this.mainid, this.ratevalue).subscribe(
              res => {
                // //console.log(res);
              }
            )
            // SessionData.SaveDataInSessionByLoginAndValidateSessionAndCheckNumber(res["data"]);
            this.toastr.success(res["message"]);
            // this.cookieService.set('lang', res.data.lang)

            this.get_Product_comment(this.mainid);
            this.LoadingProvider.CloseHttpServiceLoading()
          }
          else {
            this.toastr.error(res["message"]);
            this.LoadingProvider.CloseHttpServiceLoading()
          }
        })
    }
    else {
      if (localStorage.getItem('lang') == 'ar') {
        this.toastr.error("من فضلك قم بملئ البيانات بشكل صحيح");
      }
      else {
        this.toastr.error("please add your commit and rate");
      }
    }
  }
  productFav(id, isFav) {
    if (this.sessionData.UserIsLoggedIn == true) {
      this.LoadingProvider.ViewHttpServiceLoadingDiv()

      this.ApiService.add_delete_wishList(id).subscribe(
        res => {
          // //console.log(res);

          if (res.status == 1) {
            if (isFav == true) {
              this.toastr.success(res["message"]);
            }
            else {
              this.toastr.success(res["message"]);
            }
            // this.ngOnInit();
            this.LoadingProvider.CloseHttpServiceLoading()
          }
          else {
            this.toastr.success(res["message"]);
            this.LoadingProvider.CloseHttpServiceLoading()
          }
        });
    }
    else {
      this.router.navigate(['/LoginComponent']).then(() => {
      });
    }

  }
  choseColor(id) {
    console.log(id);

    this.color_pro = id;
    if (id != null) {
      if (localStorage.getItem('lang') == 'ar') {
        this.toastr.success("تم اختيار اللون بنجاح");
      }
      else {
        this.toastr.success("color choose succfully");
      }
    }
    else {
      if (localStorage.getItem('lang') == 'ar') {
        this.toastr.success("تم حذف اللون بنجاح");
      }
      else {
        this.toastr.success("color remove succfully");
      }
    }
  }
  chosesize(id) {
    // console.log(id);

    this.size_pro = id;
    if (id != null) {
      if (localStorage.getItem('lang') == 'ar') {
        this.toastr.success("تم اختيار الحجم بنجاح");
      }
      else {
        this.toastr.success("size choose succfully");
      }
    }
    else {
      if (localStorage.getItem('lang') == 'ar') {
        this.toastr.success("تم حذف الحجم بنجاح");
      }
      else {
        this.toastr.success("size removed succfully");
      }
    }
  }
  addTocart(id) {
    if (this.sessionData.UserIsLoggedIn == true) {
      this.LoadingProvider.ViewHttpServiceLoadingDiv()
      this.ApiService.add_Cart_sp(id, this.color_pro, this.size_pro, this.quantity_pro).subscribe(
        res => {
          // //console.log(res);
          if (res.status == 1) {
            this.toastr.success(res["message"]);
            // this.ngOnInit();
            this.LoadingProvider.CloseHttpServiceLoading()
          }
          else {
            this.toastr.success(res["message"]);
            this.LoadingProvider.CloseHttpServiceLoading()
          }
          // this.LoadingProvider.CloseHttpServiceLoading()
        });
    }
    else {
      this.router.navigate(['/LoginComponent']).then(() => {
      });
    }
  }
  addQuetity(Obj) {
    this.quantity_pro++;
    // this.quantity_pro = Obj.quantity;

  }
  MinsQuentity(Obj) {
    this.quantity_pro--;
    if (this.quantity_pro == 0) {
      this.quantity_pro = 1;
      // this.toastr.warning("Qunitity can't be 0");
    }
    // this.quantity_pro = Obj.quantity;
  }
}
