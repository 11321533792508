import { LoadingProvider } from './service/loading-provider';
import { APiService } from './service/api.service';
import { SessionData } from './service/seisson-data';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'perfume-website';
  private UserToken: string;
  private lang: string;
  sessionData = SessionData;
  constructor(
    private translate: TranslateService,
    private ApiService: APiService,
    private LoadingProvider: LoadingProvider
  ) {

    this.UserToken = localStorage.getItem('UserToken');
    this.lang = localStorage.getItem('lang');
    console.log(localStorage.getItem('lang'));

    this.StartApplication();
    if (this.lang == null) {
      this.translate.setDefaultLang("ar");
      localStorage.setItem('lang', 'ar');
    }
    else {
      this.translate.setDefaultLang(this.lang);
    }
  }
  StartApplication() {
    this.GetBasicData();
    this.CheckUserIsLoggedIn();
  }
  CheckUserIsLoggedIn() {
    if (this.UserToken === null) {
      SessionData.UserIsLoggedIn = false;
    } else if (this.UserToken !== null) {
      SessionData.UserIsLoggedIn = true;
    }
  }
  GetBasicData() {
    // this.LoadingProvider.ViewHttpServiceLoadingDiv()
    this.ApiService.Home_Footer().subscribe(
      res => {
        let data = res['data'];
        // //console.log(data);

        SessionData.Siteaddress = data.address;
        SessionData.SiteAbout = data.about;
        SessionData.SiteEmail = data.email;
        SessionData.SitePhone = data.phone;
        SessionData.SiteSnap = data.snap;
        SessionData.SiteFaceBook = data.facebook;
        SessionData.SiteTwitter = data.twitter;
        SessionData.SiteInsta = data.instagram;
        SessionData.shipping_price = data.shipping_price;
        SessionData.SiteLogo = data.logo;

        // this.LoadingProvider.CloseHttpServiceLoading()
      })
  }
  ngOnInit() {
    this.lang = localStorage.getItem('lang');
    console.log(localStorage.getItem('lang'));

    // this.StartApplication();
    if (this.lang == null) {
      this.translate.setDefaultLang("ar");
      localStorage.setItem('lang', 'ar');
    }
    else {
      this.translate.setDefaultLang(this.lang);
    }
  }
}
