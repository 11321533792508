// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase : {
    apiKey: "AIzaSyDgPJvMPGYvzctTeN5DZay2DpmvaryMMaw",
    authDomain: "women-ddb76.firebaseapp.com",
    databaseURL: "https://women-ddb76.firebaseio.com",
    projectId: "women-ddb76",
    storageBucket: "women-ddb76.appspot.com",
    messagingSenderId: "990794859972",
    appId: "1:990794859972:web:f00272690c8990e2ee1091",
    measurementId: "G-0GKDTYL0G4"
  },
  production: false,
  domainUrl: 'http://tmsouq.shoohna.com/api/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
