import { ToastrService } from 'ngx-toastr';
import { SessionData } from './../../service/seisson-data';
import { LoadingProvider } from './../../service/loading-provider';
import { NotifierService } from 'angular-notifier';
import { Router } from '@angular/router';
import { APiService } from './../../service/api.service';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-favoirt',
  templateUrl: './favoirt.component.html',
  styleUrls: ['./favoirt.component.scss']
})
export class FavoirtComponent implements OnInit {

  cartProduct = [

  ]
  private readonly notifier: NotifierService;
  ContactUs = new Array();
  constructor(
    private ApiService: APiService,
    private router: Router,
    private notifierService: NotifierService,
    private toastr: ToastrService,
    private LoadingProvider: LoadingProvider
  ) {
    this.notifier = notifierService;
  }
  public sessionData = SessionData;

  ngOnInit(): void {

    this.get_aboutus()
  }

  get_aboutus() {
    this.LoadingProvider.ViewHttpServiceLoadingDiv()
    this.ApiService.get_wishList().subscribe(
      res => {
        let resources: any[] = res["data"];
        // //console.log(res);

        this.cartProduct = resources
        let message: any = res["message"];
        this.LoadingProvider.CloseHttpServiceLoading()
      });

  }

  Deleteproduct(id) {
    this.LoadingProvider.ViewHttpServiceLoadingDiv()

    this.ApiService.add_delete_wishList(id).subscribe(
      res => {
        // //console.log(res);

        if (res.status == 1) {
          // SessionData.SaveDataInSession(res["data"]);
          this.toastr.success(res["message"]);
          // this.cookieService.set('lang', res.data.lang)
          this.ngOnInit();
          // this.router.navigate(['/HomeComponent']).then(() => {
          //   // window.location.reload();
          // });
          this.LoadingProvider.CloseHttpServiceLoading()
        }
        else {
          this.toastr.error(res["message"]);
          this.LoadingProvider.CloseHttpServiceLoading()
        }
      });
    // this.LoadingProvider.CloseHttpServiceLoading()
  }

  addTocart(id) {
    if (this.sessionData.UserIsLoggedIn == true) {
      this.LoadingProvider.ViewHttpServiceLoadingDiv()

      this.ApiService.add_Cart(id).subscribe(
        res => {
          // //console.log(res);

          if (res.status == 1) {

            this.toastr.success(res["message"]);

            this.ngOnInit();
            this.LoadingProvider.CloseHttpServiceLoading()
          }
          else {
            this.toastr.error(res["message"]);
            this.LoadingProvider.CloseHttpServiceLoading()
          }
        });
    }
    else {
      this.router.navigate(['/LoginComponent']).then(() => {
      });
    }
  }
}
