import { ToastrService } from 'ngx-toastr';
import { LoadingProvider } from './../../service/loading-provider';
import { Router } from '@angular/router';
import { APiService } from './../../service/api.service';
import { NotifierService } from 'angular-notifier';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})
export class ContactusComponent implements OnInit {

  typeingemail: any;
  PasswordType = 'password';
  typeingFirstName: boolean;
  LastName: boolean;
  typeingname: boolean;
  typeingmassage: boolean;
  imgSrc: string = "assets/images/image-placeholder.jpg";
  selectedImage: any = null;
  private readonly notifier: NotifierService;

  constructor(
    private ApiService: APiService,
    private router: Router,
    private notifierService: NotifierService,
    private toastr: ToastrService,
    private LoadingProvider: LoadingProvider
    // private cookieService: CookieService
  ) {
    this.notifier = notifierService;
  }

  ngOnInit(): void {
  }

  LogInForm = new FormGroup({
    name: new FormControl('',
    [Validators.required, Validators.pattern("^[\u0621-\u064A\u0660-\u0669a-zA-Z ]*$")]),
    message: new FormControl('',
      Validators.required),
    email: new FormControl('',[
      Validators.required,
      Validators.email]),
    phone: new FormControl('',
    [Validators.required, Validators.pattern("^[0-9]*$")]),
  })

  ChangePasswordStyle() {
    if (this.PasswordType === 'password') {
      this.PasswordType = 'text';
    } else if (this.PasswordType === 'text') {
      this.PasswordType = 'password';
    }
  }

  checkemail(event) {
    this.typeingemail = true
  }
  checkuserphone(event) {
    this.typeingFirstName = true
  }
  checkusername(event) {
    this.typeingname = true
  }
  checkuserMassage(event) {
    this.typeingmassage = true
  }
  onSubmit(formData) {
    if (formData.valid) {
      this.LoadingProvider.ViewHttpServiceLoadingDiv()
      this.ApiService.Send_message(formData.value).subscribe(
        res => {
          // //console.log(res);
          if (res.status != 0) {
            // SessionData.SaveDataInSession(res["data"]);
            this.toastr.success(res['message']);
            // this.cookieService.set('lang', res.data.lang)
            this.router.navigate(['/HomeComponent']).then(() => {
              // window.location.reload();
            });
            this.LoadingProvider.CloseHttpServiceLoading()
          }
          else {
            this.toastr.success(res['message']);
            this.LoadingProvider.CloseHttpServiceLoading()
          }
        })
    }
    else {
      if (localStorage.getItem('lang') == 'ar') {
        this.toastr.error("من فضلك قم بملئ البيانات بشكل صحيح");
      }
      else {
        this.toastr.error("please fill right data");
      }
    }
  }

}
