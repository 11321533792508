import { ToastrService } from 'ngx-toastr';
import { SessionData } from './../../service/seisson-data';
import { LoadingProvider } from './../../service/loading-provider';
import { Router } from '@angular/router';
import { APiService } from './../../service/api.service';
import { NotifierService } from 'angular-notifier';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { NgWizardConfig, THEME, StepChangedArgs, NgWizardService, } from 'ng-wizard';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {
  currentPage: any;
  cartProduct = []
  config: NgWizardConfig = {
    selected: 0,
    theme: THEME.arrows,
    toolbarSettings: {
      toolbarExtraButtons: [
        { text: 'Finish', class: 'btn btn-info', event: () => { alert("Finished!!!"); } }
      ]
    }
  };
  // public sessionData = SessionData;

  private readonly notifier: NotifierService;
  ContactUs = new Array();
  constructor(
    private ApiService: APiService,
    private router: Router,
    private notifierService: NotifierService,
    private LoadingProvider: LoadingProvider,
    private ngWizardService: NgWizardService,
    private toastr: ToastrService,
  ) {
    this.notifier = notifierService;
  }

  public sessionData = SessionData;
  TotalPrice: any = 0;
  Addresses: any;
  AddressId: any;
  Payment: any;

  LogInForm = new FormGroup({
    fname: new FormControl('', [
      Validators.required]),
    lname: new FormControl('',
      Validators.required),
    company: new FormControl('',
      Validators.required),
    address: new FormControl('',
      Validators.required),
    fulladdress: new FormControl('',
      Validators.required),
    mo7fza: new FormControl('',
      Validators.required),
    city: new FormControl('',
      Validators.required),
    post: new FormControl('',
      Validators.required),
    phone: new FormControl('',
      Validators.required),
  })
  ngOnInit(): void {
    this.currentPage = "cart"
    this.LoadingProvider.ViewHttpServiceLoadingDiv()
    this.get_aboutus();
    this.Get_Address();
    this.AddressId = 0;
    this.Payment = 0;
  }
  Get_Address() {
    // this.ContactUs = [];
    this.ApiService.Get_Address().subscribe(
      res => {
        let resources: any[] = res["data"];
        // //console.log(res);
        // this.TotalPrice = res.total_price;
        this.Addresses = resources;
        let message: any = res["message"];
        this.LoadingProvider.CloseHttpServiceLoading()
      });
  }
  ShowPage(page) {
    this.currentPage = page
  }
  CheckOut(page) {
    if (this.cartProduct != null) {
      // this.currentPage = page

      // AddressChacke
      let cartUpdateProduct = new Array();
      this.cartProduct.forEach(product => {
        let item = {
          "product_id": product.id,
          "quantity": product.quantity,
          "color_id": null,
          "size_id": null,
        }
        cartUpdateProduct.push(item);
      });
      this.ApiService.update_Cart(cartUpdateProduct).subscribe(
        res => {
          //console.log(res);
          if (res.status == 1) {
            this.currentPage = page
          }
        })
    }
    else {
      if (localStorage.getItem('lang') == 'ar') {
        this.toastr.error("من فضلك قم بإختيار المنتجات اولا");
      }
      else {
        this.toastr.error("please choose products first");
      }
    }
  }
  FinishOrder(e) {
    if (this.Payment != 0) {
      this.LoadingProvider.ViewHttpServiceLoadingDiv()
      this.ApiService.makeOrder(this.AddressId, this.Payment).subscribe(
        res => {
          //console.log(res);
          if (res.status == 1) {
            this.toastr.success(res["message"]);
            this.router.navigate(['/HomeComponent']).then(() => {
              // window.location.reload();
            });
            this.LoadingProvider.CloseHttpServiceLoading()
          }
          else {
            this.toastr.error(res["message"]);
            this.LoadingProvider.CloseHttpServiceLoading()

          }
        })
    }
    else {
      if (localStorage.getItem('lang') == 'ar') {
        this.toastr.error("من فضلك قم بأختيار طريقه الدفع اولا");
      }
      else {
        this.toastr.error("please chooose payment method first");
      }
    }
  }
  changeAddress(id) {
    this.AddressId = id;
    //console.log(id);
  }
  PaymentBank: boolean = false;
  Paymentcash: boolean = false;
  changePayMent(id) {
    if(id == 2) {
      this.Payment = 0;
      if (localStorage.getItem('lang') == 'ar') {
        Swal.fire(
          'هذه الخدمه ليست متوفره!',
          '',
          'error'
        )
      }
      else {
        Swal.fire(
          'this service not avilable!',
          '',
          'error'
        )
      }
      this.PaymentBank = false;
      this.Paymentcash = false;
    }
    else {
      this.PaymentBank = false;
      // this.Paymentcash = true;
      this.Payment = id;
    }
    // this.Payment = 0;
  }
  price_Total = '0';
  get_aboutus() {
    this.ApiService.get_Cart().subscribe(
      res => {
        let resources: any[] = res["data"];
        this.TotalPrice = res.total_price;
        this.price_Total = res.total_price + this.sessionData.shipping_price;
        // let element = document.querySelector('.comp');
        // if (this.TotalPrice == 0) {
        //   element.classList.add('disabled');
        // }
        // else {
        //   element.classList.remove('disabled');
        // }
        this.cartProduct = resources;
        let message: any = res["message"];
        this.LoadingProvider.CloseHttpServiceLoading()
      });
  }

  Deleteproduct(id) {
    this.LoadingProvider.ViewHttpServiceLoadingDiv()

    this.ApiService.delete_Cart(id).subscribe(
      res => {
        // //console.log(res);

        if (res.status == 1) {
          // SessionData.SaveDataInSession(res["data"]);
          // if (localStorage.getItem('lang') == 'ar') {
            this.toastr.success(res["message"]);
          // }
          // else {
          //   this.toastr.error("please fill right data");
          // }
          // this.cookieService.set('lang', res.data.lang)
          this.ngOnInit();
          // this.router.navigate(['/HomeComponent']).then(() => {
          //   // window.location.reload();
          // });
          this.LoadingProvider.CloseHttpServiceLoading()
        }
        else {
          if (localStorage.getItem('lang') == 'ar') {
            this.toastr.error("من فضلك قم بتسجيل الدخول اولا");
          }
          else {
            this.toastr.error("please login first");
          }
          this.LoadingProvider.CloseHttpServiceLoading()
        }
      });
    // this.LoadingProvider.CloseHttpServiceLoading()
  }
  addQuetity(Obj) {
    Obj.quantity++;
    // let proQuentity;
    // this.ApiService.Get_product(Obj.id).subscribe(
    // 	res => {
    // 		let resources: any = res["data"];
    // 		proQuentity = resources.quantity
    // 	})
    // 	if(Obj.quantity > proQuentity) {
    // 		Obj.quantity = proQuentity;
    // 		this.toastr.warning("Qunitity can't be Larage than Product Quentity");
    // 	}
  }
  MinsQuentity(Obj) {
    Obj.quantity--;
    if (Obj.quantity == 0) {
      Obj.quantity = 1;
      // this.toastr.warning("Qunitity can't be 0");
    }
  }
  updateCart() {
    let cartUpdateProduct = new Array();
    this.cartProduct.forEach(product => {
      let item = {
        "product_id": product.id,
        "quantity": product.quantity,
        "color_id": null,
        "size_id": null,
      }
      cartUpdateProduct.push(item);
    });
    this.LoadingProvider.ViewHttpServiceLoadingDiv()

    this.ApiService.update_Cart(cartUpdateProduct).subscribe(
      res => {
        // //console.log(res);
        if (res.status == 1) {
          // SessionData.SaveDataInSession(res["data"]);
          // if (localStorage.getItem('lang') == 'ar') {
            this.toastr.success(res["message"]);
          // }
          // else {
          //   this.toastr.error("please fill right data");
          // }
          // this.cookieService.set('lang', res.data.lang)
          this.ngOnInit();
          // this.router.navigate(['/HomeComponent']).then(() => {
          //   // window.location.reload();
          // });
          this.LoadingProvider.CloseHttpServiceLoading()
        }
        else {
          // if (localStorage.getItem('lang') == 'ar') {
            this.toastr.error(res["message"]);
          // }
          // else {
          //   this.toastr.error("please fill right data");
          // }
          this.LoadingProvider.CloseHttpServiceLoading()
        }
      });
    // this.LoadingProvider.CloseHttpServiceLoading()
  }

  // wizerd
  showPreviousStep(event?: Event) {
    this.ngWizardService.previous();
  }

  showNextStep(event?: Event, id?: any) {
    if (id == 1) {
      this.ngWizardService.next();
    }
    else if (id == 2 && this.AddressId != 0) {
      this.ngWizardService.next();
    }
    else if (id == 2 && this.AddressId == 0) {
      if (localStorage.getItem('lang') == 'ar') {
        this.toastr.error("من فضلك قم باختيار العنوان اولا");
      }
      else {
        this.toastr.error("please fill address first");
      }
    }
  }

  resetWizard(event?: Event) {
    this.ngWizardService.reset();
  }

  setTheme(theme: THEME) {
    this.ngWizardService.theme(theme);
  }

  stepChanged(args: StepChangedArgs) {
    //console.log(args.step);
  }

  StepCompelete() {

  }

  ReturnInputIsChecked(Id, Type): boolean {
    let IsChecked: boolean;


    return IsChecked;
  }
}
