import { SessionData } from './seisson-data';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http'
import { Observable, of, } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../src/environments/environment';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';


import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';


@Injectable({
  providedIn: 'root'
})
export class APiService {

  endpoint = environment.domainUrl;
  user_id: string;
  token: string;
  lang: string;
  private sessionData = SessionData;
  constructor(public afAuth: AngularFireAuth, private http: HttpClient, private cookieService: CookieService) {
    // this.token = this.cookieService.get('user_token')
    // //console.log(localStorage.getItem('UserToken'));
    if (localStorage.getItem('lang') == null || localStorage.getItem('lang') == 'ar') {
      localStorage.setItem('lang', 'ar')
    }
    else if (localStorage.getItem('lang') == 'ar') {
      localStorage.setItem('lang', 'ar')
    }
    else {
      localStorage.setItem('lang', 'en')
    }
    console.log(localStorage.getItem('lang'));
  }
  httpOptions = {
    headers: new HttpHeaders({
      'content': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('UserToken'),
      'lang': localStorage.getItem('lang')
    })
  }
  httpOptionsReset = {
    headers: new HttpHeaders({
      'content': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('UserTokenn'),
      'lang': localStorage.getItem('lang')
    })
  }
  httpOptionsWithOutToken() {


    const contentHeaders = new HttpHeaders({
      // 'content': 'application/json',
      // 'Authorization': 'Bearer ' + localStorage.getItem('UserToken'),
      'lang': localStorage.getItem('lang')
    });
    return contentHeaders;
  }
  // authantkation
  logIn(formValue): Observable<any> {
    const contentHeaders = new HttpHeaders({
      'lang': localStorage.getItem('lang')
    });

    const formData: FormData = new FormData();
    formData.append('eamilOrPhone', formValue['email']);
    formData.append('password', formValue['password']);
    formData.append('fire_base_token', '5488523062');
    return this.http.post(this.endpoint + 'Auth_general/login', formData, { headers: contentHeaders })
  }

  logInSocial(formValue): Observable<any> {
    const contentHeaders = new HttpHeaders({
      'lang': localStorage.getItem('lang')
    });

    const formData: FormData = new FormData();
    formData.append('email', formValue['email']);
    // formData.append('password', formValue['password']);
    // formData.append('fire_base_token', '5488523062');
    return this.http.post(this.endpoint + 'Auth_general/login_social', formData, { headers: contentHeaders })
  }

  register(formValue, image): Observable<any> {
    const contentHeaders = new HttpHeaders({
      'lang': localStorage.getItem('lang')
    });

    const formData: FormData = new FormData();
    formData.append('phone', formValue['phone']);
    formData.append('password', formValue['password']);
    formData.append('email', formValue['email']);
    formData.append('password_confirmation', formValue['compassword']);
    formData.append('verify_type', '1');
    formData.append('username', formValue['username']);
    formData.append('lat', '');
    formData.append('lng', '');
    formData.append('image', '');
    formData.append('fire_base_token', 'nhbgvcxkkkkmmmkmmkm');
    return this.http.post(this.endpoint + 'Auth_general/register', formData, { headers: contentHeaders })
  }

  registerSocial(formValue, image): Observable<any> {
    const contentHeaders = new HttpHeaders({
      'lang': localStorage.getItem('lang')
    });

    const formData: FormData = new FormData();
    formData.append('phone', '');
    // formData.append('password', formValue['password']);
    formData.append('email', formValue.email);
    // formData.append('password_confirmation', formValue['compassword']);
    // formData.append('verify_type', '1');
    formData.append('frist_name', formValue.given_name);
    formData.append('last_name', formValue.family_name);

    formData.append('lat', '');
    formData.append('username', formValue.given_name + formValue.family_name);

    formData.append('lng', '');
    formData.append('image', '');
    // formData.append('fire_base_token', 'nhbgvcxkkkkmmmkmmkm');
    return this.http.post(this.endpoint + 'Auth_general/register_social', formData, { headers: contentHeaders })
  }

  forget_password(formValue): Observable<any> {
    const contentHeaders = new HttpHeaders({
      'lang': localStorage.getItem('lang')
    });

    const formData: FormData = new FormData();
    formData.append('email', formValue['email']);
    return this.http.post(this.endpoint + 'Auth_general/forget_password', formData, { headers: contentHeaders })
  }
  check_code(formValue): Observable<any> {
    const contentHeaders = new HttpHeaders({
      'lang': localStorage.getItem('lang')
    });

    const formData: FormData = new FormData();
    formData.append('code', formValue['code']);
    return this.http.post(this.endpoint + 'Auth_general/check_code', formData, { headers: contentHeaders })
  }
  reset_password(formValue): Observable<any> {
    // //console.log(formValue);

    const formData: FormData = new FormData();
    formData.append('password', formValue['password']);
    formData.append('confirm_password', formValue['passwordConfirmation']);
    return this.http.post(this.endpoint + 'Auth_private/reset_password', formData, this.httpOptionsReset)
  }
  LogOut(): Observable<any> {
    return this.http.get(this.endpoint + 'Auth_private/logout')
  }
  Get_info(): Observable<any> {
    return this.http.get(this.endpoint + 'Auth_private/my_info', this.httpOptions)
  }
  Edit_data(formValue, image): Observable<any> {
    // //console.log(image);

    const formData: FormData = new FormData();
    formData.append('phone', formValue['phone']);
    formData.append('email', formValue['email']);
    formData.append('username', formValue['username']);
    formData.append('lat', '');
    formData.append('lng', '');
    if (image != null) {
      formData.append('image', image);
    }
    return this.http.post(this.endpoint + 'Auth_private/edit_profile', formData, this.httpOptions)
  }
  Change_password(formValue): Observable<any> {
    // //console.log(image);

    const formData: FormData = new FormData();
    formData.append('newPassword', formValue['newPassword']);
    formData.append('oldPassword', formValue['oldPassword']);
    // formData.append('username', formValue['username']);
    // formData.append('lat', '');
    // formData.append('lng', '');
    // formData.append('image', image);
    return this.http.post(this.endpoint + 'Auth_private/change_password', formData, this.httpOptions)
  }
  // news
  get_news(): Observable<any> {
    return this.http.get(this.endpoint + 'News/get_News', this.httpOptions);
  }
  get_one_news(id): Observable<any> {
    return this.http.get(this.endpoint + 'News/single_new/' + id, this.httpOptions);
  }
  //wish list
  get_wishList(): Observable<any> {
    return this.http.get(this.endpoint + 'Auth_private/my_wishlist', this.httpOptions);
  }
  add_delete_wishList(id): Observable<any> {
    // //console.log(this.httpOptions);

    return this.http.post(this.endpoint + 'Product/wishlist/' + id, null, this.httpOptions);
  }
  get_Cart(): Observable<any> {
    return this.http.get(this.endpoint + 'Auth_private/my_cart', this.httpOptions);
  }
  add_Cart(id): Observable<any> {
    // //console.log(this.httpOptions);
    const formData: FormData = new FormData();
    formData.append('quantity', "1");
    formData.append('color_id', null);
    formData.append('size_id', null);
    return this.http.post(this.endpoint + 'Product/add_to_cart/' + id, formData, this.httpOptions);
  }
  add_Cart_sp(id, color, size, quantity): Observable<any> {
    //console.log(this.httpOptions);
    const formData: FormData = new FormData();
    formData.append('quantity', quantity);
    formData.append('color_id', color);
    formData.append('size_id', size);
    return this.http.post(this.endpoint + 'Product/add_to_cart/' + id, formData, this.httpOptions);
  }
  delete_Cart(id): Observable<any> {
    return this.http.post(this.endpoint + 'Product/delete_from_cart/' + id, null, this.httpOptions);
  }
  update_Cart(products): Observable<any> {
    var data = { "products": products }
    return this.http.post(this.endpoint + 'Product/update_cart', data, this.httpOptions);
  }
  Get_Address(): Observable<any> {
    return this.http.get(this.endpoint + 'Order/user_address', this.httpOptions);
  }
  Add_Address(formdata): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('address', formdata['address']);
    formData.append('city', formdata['city']);
    formData.append('type', formdata['type']);
    formData.append('flat', formdata['flat']);
    formData.append('lat', '');
    formData.append('lng', '');
    formData.append('buliding', formdata['buliding']);
    return this.http.post(this.endpoint + 'Auth_private/add_address', formData, this.httpOptions);
  }
  //order
  Get_Orders(): Observable<any> {
    return this.http.get(this.endpoint + 'Order/my_order_website', this.httpOptions);
  }
  makeOrder(address_id, payment_method): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('address_id', address_id);
    formData.append('payment_method', payment_method);
    return this.http.post(this.endpoint + 'Order/add_order_once', formData, this.httpOptions);
  }
  SinglOrder(id): Observable<any> {
    return this.http.get(this.endpoint + 'Order/single_order/' + id, this.httpOptions);
  }
  //product
  get_Products(): Observable<any> {
    const contentHeaders = new HttpHeaders({
      'lang': localStorage.getItem('lang')
    });
    return this.http.get(this.endpoint + 'Product/get_products?type=2', { headers: contentHeaders });
  }
  get_Products_dtailes(id): Observable<any> {
    const contentHeaders = new HttpHeaders({
      'lang': localStorage.getItem('lang')
    });
    return this.http.get(this.endpoint + 'Product/product_details/' + id, { headers: contentHeaders });
  }
  get_Category(): Observable<any> {
    const contentHeaders = new HttpHeaders({
      'lang': localStorage.getItem('lang')
    });
    return this.http.get(this.endpoint + 'Home/get_main_cat', { headers: contentHeaders });
  }
  get_ProducbyCategory(id): Observable<any> {
    const contentHeaders = new HttpHeaders({
      'lang': localStorage.getItem('lang')
    });
    return this.http.get(this.endpoint + 'Product/products_by_category/' + id, { headers: contentHeaders });
  }
  get_Producbyname(type, catId, name): Observable<any> {
    const contentHeaders = new HttpHeaders({
      'lang': localStorage.getItem('lang')
    });
    return this.http.get(this.endpoint + 'Home/search_by_name?name=' + name + '&cat_id=' + catId + '&type=' + type, { headers: contentHeaders });
  }
  //comments
  get_Produccommit(id): Observable<any> {
    const contentHeaders = new HttpHeaders({
      'lang': localStorage.getItem('lang')
    });
    return this.http.get(this.endpoint + 'Product/get_comments/' + id, { headers: contentHeaders });
  }
  add_Produccommit(id, formdata): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('comment', formdata["comment"]);
    return this.http.post(this.endpoint + 'rate_comment/save_my_comment/' + id, formData, this.httpOptions);
  }
  add_ProducRate(id, formdata): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('rate', formdata);
    return this.http.post(this.endpoint + 'rate_comment/save_my_rate/' + id, formData, this.httpOptions);
  }
  // about us and contact us
  get_AboutUs(): Observable<any> {
    const contentHeaders = new HttpHeaders({
      'lang': localStorage.getItem('lang')
    });
    return this.http.get(this.endpoint + 'general/about_us', { headers: contentHeaders });
  }
  get_ContactUs(): Observable<any> {
    const contentHeaders = new HttpHeaders({
      'lang': localStorage.getItem('lang')
    });
    return this.http.get(this.endpoint + 'general/get_info', { headers: contentHeaders });
  }
  Send_message(formValue): Observable<any> {
    // //console.log(formValue);
    const contentHeaders = new HttpHeaders({
      'lang': localStorage.getItem('lang')
    });
    const formData: FormData = new FormData();
    formData.append('name', formValue['name']);
    formData.append('email', formValue['email']);
    formData.append('phone', formValue['phone']);
    formData.append('message', formValue['message']); //email
    return this.http.post(this.endpoint + 'general/contact_us', formData, { headers: contentHeaders })
  }

  Home_page(): Observable<any> {
    const contentHeaders = new HttpHeaders({
      'lang': localStorage.getItem('lang')
    });
    return this.http.get(this.endpoint + 'Home/homeWebsite', { headers: contentHeaders })
  }

  Home_Footer(): Observable<any> {
    const contentHeaders = new HttpHeaders({
      'lang': localStorage.getItem('lang')
    });

    return this.http.get(this.endpoint + 'Home/homeFooter', { headers: contentHeaders })
  }
  Brands(): Observable<any> {
    const contentHeaders = new HttpHeaders({
      'lang': localStorage.getItem('lang')
    });
    return this.http.get(this.endpoint + 'Home/brands', { headers: contentHeaders })
  }
  Home_subscribe(email): Observable<any> {
    const contentHeaders = new HttpHeaders({
      'lang': localStorage.getItem('lang')
    });
    const formData: FormData = new FormData();
    formData.append('email', email);
    return this.http.post(this.endpoint + 'Home/subscribe', formData, { headers: contentHeaders });
  }
}
