import { LoadingProvider } from './../../service/loading-provider';
import { NotifierService } from 'angular-notifier';
import { Router } from '@angular/router';
import { APiService } from './../../service/api.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  products: any
  category: any
  catId = null
  type = 2
  name = ''
  constructor(
    private ApiService: APiService,
    private router: Router,
    private notifierService: NotifierService,
    private LoadingProvider: LoadingProvider
  ) { }
  Selectid = 0;
  ngOnInit(): void {
    this.get_category()
    this.get_news()
  }

  get_news() {
    this.Selectid = 0;
    this.LoadingProvider.ViewHttpServiceLoadingDiv()
    this.ApiService.get_Products().subscribe(
      res => {
        let resources: any[] = res["data"];
        // //console.log(res);

        this.products = resources;
        let message: any = res["message"];
        this.LoadingProvider.CloseHttpServiceLoading()
      });

  }
  get_category() {
    this.LoadingProvider.ViewHttpServiceLoadingDiv()
    this.ApiService.get_Category().subscribe(
      res => {
        let resources: any[] = res["data"];
        // //console.log(res);

        this.category = resources;
        let message: any = res["message"];
      });
    this.LoadingProvider.CloseHttpServiceLoading()
  }
  GetProductByCat(id) {
    this.Selectid = id;
    this.LoadingProvider.ViewHttpServiceLoadingDiv();
    this.catId = id
    this.ApiService.get_ProducbyCategory(id).subscribe(
      res => {
        let resources: any[] = res["data"].all_product;
        // //console.log(res);

        this.products = resources;
        let message: any = res["message"];
        this.LoadingProvider.CloseHttpServiceLoading()
      });

  }
  GetProductBynamet() {
    var inputValue = (<HTMLInputElement>document.getElementById("searchInput")).value;
    // //console.log(inputValue);

    this.LoadingProvider.ViewHttpServiceLoadingDiv();
    this.ApiService.get_Producbyname(this.type, this.catId, inputValue).subscribe(
      res => {
        let resources: any[] = res["data"];
        // //console.log(res);

        this.products = resources;
        let message: any = res["message"];
        this.LoadingProvider.CloseHttpServiceLoading()
      });

  }
}
