import { SessionData } from './seisson-data';
import { LoadingProvider } from './loading-provider';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { APiService } from './api.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http'
import { Observable, of, } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../src/environments/environment';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';


import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {

  endpoint = environment.domainUrl;
  user_id: string;
  token: string;

  constructor(public afAuth: AngularFireAuth,
    private http: HttpClient, private cookieService: CookieService,
    private ApiService: APiService,
    private router: Router,
    private toastr: ToastrService,

    private LoadingProvider: LoadingProvider,
  ) {
    // this.token = this.cookieService.get('user_token')
    // //console.log(localStorage.getItem('UserToken'));

  }


  httpOptions = {
    headers: new HttpHeaders({
      'content': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('UserToken')
      //this.cookieService.get('user_token'),
    })
  }

  doFacebookLogin() {
    return new Promise<any>((resolve, reject) => {
      let provider = new firebase.auth.FacebookAuthProvider();
      this.afAuth
        .signInWithPopup(provider)
        .then(res => {
          resolve(res);
          //console.log(res.additionalUserInfo.profile);


        }, err => {
          //console.log(err);
          reject(err);
        })
    })
  }
  doGoogleLogin() {
    return new Promise<any>((resolve, reject) => {
      let provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope('profile');
      provider.addScope('email');
      this.afAuth
        .signInWithPopup(provider)
        .then(res => {
          // //console.log(res.additionalUserInfo.profile);
          // this.ApiService.registerSocial(res.additionalUserInfo.profile, '')

          this.ApiService.registerSocial(res.additionalUserInfo.profile, '').subscribe(
            res => {
              //console.log(res);
              if (res["data"] != null) {
                // SessionData.SaveDataInSessionByLoginAndValidateSessionAndCheckNumber(res["data"]);
                // this.notifier.notify("success", "تم  انشاء حساب بنجاح, ");
                //console.log(res["data"]);

                SessionData.SaveDataInSessionByLoginAndValidateSessionAndCheckNumber(res["data"]);
                // this.notifier.notify("success", "Welcome, " + res.data.username);
                this.toastr.success("تم  تسجيل الدخول بنجاح أهلا بك", "");

                // this.cookieService.set('lang', res.data.lang)
                this.router.navigate(['/HomeComponent']).then(() => {
                  // window.location.reload();
                });
                this.LoadingProvider.CloseHttpServiceLoading()
              }
              else {
                // this.notifier.notify("warning", "خطأ في البيانات");
                this.toastr.error("خطأ في البيانات");

                this.LoadingProvider.CloseHttpServiceLoading()
              }
            })
          resolve(res);
        }, err => {
          //console.log(err);
          reject(err);
        })
    })
  }
  doGoogleLogin_page() {
    return new Promise<any>((resolve, reject) => {
      let provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope('profile');
      provider.addScope('email');
      this.afAuth
        .signInWithPopup(provider)
        .then(res => {
          // //console.log(res.additionalUserInfo.profile);
          // this.ApiService.registerSocial(res.additionalUserInfo.profile, '')

          this.ApiService.logInSocial(res.additionalUserInfo.profile).subscribe(
            res => {
              //console.log(res);
              if (res["data"] != null) {
                // SessionData.SaveDataInSessionByLoginAndValidateSessionAndCheckNumber(res["data"]);
                // SessionData.SaveDataInSessionByLoginAndValidateSessionAndCheckNumber(res["data"]);
                // this.notifier.notify("success", "تم  انشاء حساب بنجاح, ");
                //console.log(res["data"]);

                SessionData.SaveDataInSessionByLoginAndValidateSessionAndCheckNumber(res["data"]);
                // this.notifier.notify("success", "Welcome, " + res.data.username);
                this.toastr.success("تم  تسجيل الدخول بنجاح أهلا بك", "");

                // this.cookieService.set('lang', res.data.lang)
                this.router.navigate(['/HomeComponent']).then(() => {
                  // window.location.reload();
                });
                this.LoadingProvider.CloseHttpServiceLoading()
              }
              else {
                // this.notifier.notify("warning", "خطأ في البيانات");
                this.toastr.error("خطأ في البيانات");

                this.LoadingProvider.CloseHttpServiceLoading()
              }
            })
          resolve(res);
        }, err => {
          //console.log(err);
          reject(err);
        })
    })
  }
}
