import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { APiService } from '../../service/api.service';
import { Router } from '@angular/router';
import { NotifierService } from "angular-notifier";
import { CookieService } from 'ngx-cookie-service';
// import { LocalStorage } from '@ngx-pwa/local-storage';
import { LoadingProvider } from '../../service/loading-provider';
import { SessionData } from '../../service/seisson-data';
import Swal from 'sweetalert2';
import { AuthenticationService } from 'src/app/service/AuthenticationService.service';

@Component({
  selector: 'app-register-page',
  templateUrl: './register-page.component.html',
  styleUrls: ['./register-page.component.scss']
})

export class RegisterPageComponent implements OnInit {
  typeingemail: any;
  typeingname: boolean;
  PasswordType = 'password';
  typeingFirstName: boolean;
  LastName: boolean;
  imgSrc: string = "assets/images/image-placeholder.jpg";
  selectedImage: any = null;
  private readonly notifier: NotifierService;

  constructor(
    private ApiService: APiService,
    private router: Router,
    private toastr: ToastrService,

    private LoadingProvider: LoadingProvider,
    private authenticationService: AuthenticationService

    // private cookieService: CookieService
  ) {
  }

  ngOnInit(): void {
    this.typeingname = false
  }
  LogInForm = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.email]),
    password: new FormControl('',
      Validators.required),
    phone: new FormControl('',
    [Validators.required, Validators.pattern("^[0-9]*$")]),
    compassword: new FormControl('',
      Validators.required),
    username: new FormControl('',
    [Validators.required, Validators.pattern("^[\u0621-\u064A\u0660-\u0669a-zA-Z]*$")]),
    // image: new FormControl(''),
  })

  showPreview(event: any) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => this.imgSrc = e.target.result;
      reader.readAsDataURL(event.target.files[0]);
      this.selectedImage = event.target.files[0];
    }
    else {
      this.imgSrc = "assets/images/image-placeholder.jpg";
      this.selectedImage = null;
    }
  }

  ChangePasswordStyle() {
    if (this.PasswordType === 'password') {
      this.PasswordType = 'text';
    } else if (this.PasswordType === 'text') {
      this.PasswordType = 'password';
    }
  }

  checkemail(event) {
    this.typeingemail = true
    if (!/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(event.target.value)) {

      event.target.classList.add('is-invalid');
    }
    else {

      event.target.classList.remove('is-invalid');
    }
  }
  checkuserphone(event) {
    this.typeingFirstName = true
  }
  checkusername(event) {
    this.typeingname = true
  }
  onSubmit(formData) {
    //console.log(formData);
    if (formData.value.password == formData.value.compassword) {
      if (formData.valid) {
        this.LoadingProvider.ViewHttpServiceLoadingDiv()
        this.ApiService.register(formData.value, this.selectedImage).subscribe(
          res => {
            // //console.log(res);
            if (res["data"] != null) {
              // SessionData.SaveDataInSessionByLoginAndValidateSessionAndCheckNumber(res["data"]);
              // this.notifier.notify("success", "تم  انشاء حساب بنجاح, ");
              SessionData.SaveDataInSessionByLoginAndValidateSessionAndCheckNumber(res["data"]);
              // this.notifier.notify("success", "Welcome, " + res.data.username);
              this.toastr.success(res["message"]);

              // this.cookieService.set('lang', res.data.lang)
              this.router.navigate(['/HomeComponent']).then(() => {
                window.location.reload();
              });
              this.LoadingProvider.CloseHttpServiceLoading()
            }
            else if(res["message"] == 'phone is already taken') {
              // this.notifier.notify("warning", "خطأ في البيانات");
              this.toastr.error(res["message"]);

              this.LoadingProvider.CloseHttpServiceLoading()
            }
            else if(res["message"] == 'phone is already taken') {
              // this.notifier.notify("warning", "خطأ في البيانات");
              this.toastr.error(res["message"]);

              this.LoadingProvider.CloseHttpServiceLoading()
            }
            else if(res["message"] == 'email is already taken') {
              // this.notifier.notify("warning", "خطأ في البيانات");
              this.toastr.error(res["message"]);

              this.LoadingProvider.CloseHttpServiceLoading()
            }
            else {
              // this.notifier.notify("warning", "خطأ في البيانات"); email is already taken
              this.toastr.error(res["message"]);

              this.LoadingProvider.CloseHttpServiceLoading()
            }
          })
      }
      else {
        if (localStorage.getItem('lang') == 'ar') {
          this.toastr.error("من فضلك قم بملئ البيانات بشكل صحيح");
        }
        else {
          this.toastr.error("please fill right data");
        }
      }
    }
    else {
      if (localStorage.getItem('lang') == 'ar') {
        this.toastr.error("كلمة السر غير متطابقه");
      }
      else {
        this.toastr.error("password not matching");
      }
    }
  }
  login() {
    this.authenticationService.doFacebookLogin();
  }
  loginGoogle() {

    this.authenticationService.doGoogleLogin();
  }
}
