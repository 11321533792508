import { ToastrService } from 'ngx-toastr';
import { SessionData } from './../../service/seisson-data';
import { LoadingProvider } from './../../service/loading-provider';
import { Router } from '@angular/router';
import { APiService } from './../../service/api.service';
import { NotifierService } from 'angular-notifier';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-myaddress',
  templateUrl: './myaddress.component.html',
  styleUrls: ['./myaddress.component.scss']
})
export class MyaddressComponent implements OnInit {

  Addresses = [

  ]

  private readonly notifier: NotifierService;
  ContactUs = new Array();
  currentPage;
  constructor(
    private ApiService: APiService,
    private router: Router,
    private notifierService: NotifierService,
    private toastr: ToastrService,
    private LoadingProvider: LoadingProvider
  ) {
    this.notifier = notifierService;
  }
  public sessionData = SessionData;
  TotalPrice: any
  LogInForm = new FormGroup({
    address: new FormControl('',
      Validators.required),
    city: new FormControl('',
      Validators.required),
    type: new FormControl('',
      Validators.required),
    flat: new FormControl('',
      Validators.required),
    buliding: new FormControl('',
      Validators.required),
  })

  ngOnInit(): void {
    this.currentPage = 'list'

    this.Get_Address()
  }
  Back() {
    this.currentPage = 'list'
  }
  Get_Address() {
    // this.ContactUs = [];
    this.LoadingProvider.ViewHttpServiceLoadingDiv();
    this.ApiService.Get_Address().subscribe(
      res => {
        let resources: any[] = res["data"];
        // //console.log(res);
        // this.TotalPrice = res.total_price;
        this.Addresses = resources;
        let message: any = res["message"];
        this.LoadingProvider.CloseHttpServiceLoading()
      });
    // this.LoadingProvider.CloseHttpServiceLoading()
  }
  add_address() {
    this.currentPage = "add"
  }
  onSubmit(formData) {
    if (formData.valid) {
      this.LoadingProvider.ViewHttpServiceLoadingDiv()
      this.ApiService.Add_Address(formData.value).subscribe(
        res => {
          // //console.log(res);
          if (res.status != 0) {
            // SessionData.SaveDataInSession(res["data"]);
            this.toastr.success(res["message"]);
            // this.cookieService.set('lang', res.data.lang)
            this.ngOnInit();
            this.LogInForm.reset();
            // this.router.navigate(['/ResetPasswordComponent']).then(() => {
            //   // window.location.reload();
            // });
            this.LoadingProvider.CloseHttpServiceLoading()
          }
          else {
            this.toastr.success(res["message"]);
            this.LoadingProvider.CloseHttpServiceLoading()
          }
        })
    }
    else {
      if (localStorage.getItem('lang') == 'ar') {
        this.toastr.error("من فضلك قم بملئ البيانات بشكل صحيح");
      }
      else {
        this.toastr.error("please fill right data");
      }
    }
  }
}
