import { APiService } from './../../service/api.service';
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-news-detailes',
  templateUrl: './news-detailes.component.html',
  styleUrls: ['./news-detailes.component.scss']
})
export class NewsDetailesComponent implements OnInit {

  @Input() NewsDetails: any;
  mainid: any;
  new_datiles = new Array();
  constructor(
    private ApiService: APiService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.mainid = +this.route.snapshot.paramMap.get('id');
    this.get_Dtiales_news(this.mainid);
  }
  get_Dtiales_news(id) {
    this.new_datiles = []
    this.ApiService.get_one_news(id).subscribe(
      res => {
        let resources: any[] = res["data"];
        // //console.log(res);

        this.new_datiles.push(resources);
        // let message: any = res["message"];
      });

  }
}
