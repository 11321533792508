import { ToastrService } from 'ngx-toastr';
import { APiService } from './../../service/api.service';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { LoadingProvider } from './../../service/loading-provider';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-changepassworrd',
  templateUrl: './changepassworrd.component.html',
  styleUrls: ['./changepassworrd.component.scss']
})
export class ChangepassworrdComponent implements OnInit {

  typeingemail: any;
  imgSrc: string = "assets/images/image-placeholder.jpg";
  selectedImage: any = null;
  private readonly notifier: NotifierService;

  constructor(
    private ApiService: APiService,
    private router: Router,
    private notifierService: NotifierService,
    private toastr: ToastrService,
    private LoadingProvider: LoadingProvider
    // private cookieService: CookieService
  ) {
    this.notifier = notifierService;
  }

  ngOnInit(): void {
  }
  LogInForm = new FormGroup({
    oldPassword: new FormControl('',
      Validators.required),
    newPassword: new FormControl('',
      Validators.required),
  })
  showPreview(event: any) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => this.imgSrc = e.target.result;
      reader.readAsDataURL(event.target.files[0]);
      this.selectedImage = event.target.files[0];
    }
    else {
      this.imgSrc = "assets/images/image-placeholder.jpg";
      this.selectedImage = null;
    }
  }
  checkemail(event) {

  }
  onSubmit(formData) {
    if (formData.valid) {
      this.LoadingProvider.ViewHttpServiceLoadingDiv()
      this.ApiService.Change_password(formData.value).subscribe(
        res => {
          // //console.log(res);
          if (res["status"] == 1) {
            // SessionData.SaveDataInSessionByLoginAndValidateSessionAndCheckNumber(res["data"]);
            this.toastr.success(res["message"]);
            // this.cookieService.set('lang', res.data.lang)
            // this.router.navigate(['/LoginComponent']).then(() => {
            //   // window.location.reload();
            // });
            this.LoadingProvider.CloseHttpServiceLoading()
          }
          else {
            this.toastr.error(res["message"]);
            this.LoadingProvider.CloseHttpServiceLoading()
          }
        })
    }
    else {
      if (localStorage.getItem('lang') == 'ar') {
        this.toastr.error("من فضلك قم بملئ البيانات بشكل صحيح");
      }
      else {
        this.toastr.error("please fill right data");
      }
    }
  }

}
