import { APiService } from './../../service/api.service';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { LoadingProvider } from './../../service/loading-provider';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
  aboutUs = new Array();
  constructor(
    private ApiService: APiService,
    private router: Router,
    private notifierService: NotifierService,
    private LoadingProvider: LoadingProvider
  ) { }

  ngOnInit(): void {
    this.LoadingProvider.ViewHttpServiceLoadingDiv()
    this.get_aboutus()
  }
  get_aboutus() {
    this.aboutUs = [];
    this.ApiService.get_AboutUs().subscribe(
      res => {
        let resources: any[] = res["data"];
        // //console.log(res);

        this.aboutUs.push(resources);
        let message: any = res["message"];
        this.LoadingProvider.CloseHttpServiceLoading()
      });

  }
}
