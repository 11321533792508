import { OrdersComponent } from './Components/orders/orders.component';
import { ContactusComponent } from './Components/contactus/contactus.component';
import { ProductDetailesComponent } from './Components/product-detailes/product-detailes.component';
import { NewsDetailesComponent } from './Components/news-detailes/news-detailes.component';
import { FavoirtComponent } from './Components/favoirt/favoirt.component';
import { CartComponent } from './Components/cart/cart.component';
import { MyInfoComponent } from './Components/my-info/my-info.component';
import { MyaddressComponent } from './Components/myaddress/myaddress.component';
import { MyAccountComponent } from './Components/my-account/my-account.component';
import { HomeComponent } from './Components/home/home.component';
import { NewsComponent } from './Components/news/news.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './Components/login/login.component';
import { ResetPasswordComponent } from './Components/reset-password/reset-password.component';
import { RegisterPageComponent } from './Components/register-page/register-page.component';
import { ForgetPasswordComponent } from './Components/forget-password/forget-password.component';
import { WriteCodeComponent } from './Components/write-code/write-code.component';
import { AboutUsComponent } from './Components/about-us/about-us.component';
import { ClientsComponent } from './Components/clients/clients.component';
import { ProductsComponent } from './Components/products/products.component';
import { AuthenticationGuard } from './service/authentication-guard';
import { ChangepassworrdComponent } from './Components/changepassworrd/changepassworrd.component';


const routes: Routes = [
  { path: 'ForgetPasswordComponent', component: ForgetPasswordComponent },
  { path: 'LoginComponent', component: LoginComponent },
  { path: 'RegisterPageComponent', component: RegisterPageComponent },
  { path: 'WriteCodeComponent', component: WriteCodeComponent },
  { path: 'ResetPasswordComponent', component: ResetPasswordComponent },
  { path: 'AboutUsComponent', component: AboutUsComponent },
  { path: 'NewsComponent', component: NewsComponent, canActivate: [AuthenticationGuard] },
  { path: 'HomeComponent', component: HomeComponent },
  { path: '', component: HomeComponent },
  { path: 'clientComponent', component: ClientsComponent },
  { path: 'MyAccountComponent', component: MyAccountComponent, canActivate: [AuthenticationGuard] },
  { path: 'MyAddressComponent', component: MyaddressComponent, canActivate: [AuthenticationGuard] },
  { path: 'MyInfoComponent', component: MyInfoComponent, canActivate: [AuthenticationGuard] },
  { path: 'CardComponent', component: CartComponent, canActivate: [AuthenticationGuard] },
  { path: 'faveComponent', component: FavoirtComponent, canActivate: [AuthenticationGuard] },
  { path: 'changePassword', component: ChangepassworrdComponent, canActivate: [AuthenticationGuard] },
  { path: 'MyOrders', component: OrdersComponent, canActivate: [AuthenticationGuard] },
  { path: 'ProductDetailesComponent/:id', component: ProductDetailesComponent },
  { path: 'ProductComponent', component: ProductsComponent },
  { path: 'contactusComponent', component: ContactusComponent },
  { path: 'NewsDetailesComponent/:id', component: NewsDetailesComponent, data: { title: 'تفاصيل الخبر' }, canActivate: [AuthenticationGuard] },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
