import { ToastrService } from 'ngx-toastr';
import { LoadingProvider } from './../../service/loading-provider';
import { Router } from '@angular/router';
import { APiService } from './../../service/api.service';
import { NotifierService } from 'angular-notifier';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-my-info',
  templateUrl: './my-info.component.html',
  styleUrls: ['./my-info.component.scss']
})
export class MyInfoComponent implements OnInit {

  typeingemail: any;
  typeingFirstName: any;
  typeingname: any
  imgSrc: string = "assets/images/image-placeholder.jpg";
  selectedImage: any = null;
  private readonly notifier: NotifierService;

  constructor(
    private ApiService: APiService,
    private router: Router,
    private notifierService: NotifierService,
    private toastr: ToastrService,
    private LoadingProvider: LoadingProvider
    // private cookieService: CookieService
  ) {
    this.notifier = notifierService;
  }

  ngOnInit(): void {
    this.LoadingProvider.ViewHttpServiceLoadingDiv()
    this.ApiService.Get_info().subscribe(
      res => {
        let resources: any[] = res["data"];
        // //console.log(res);
        this.LogInForm.setValue({
          email: resources["email"],
          phone: resources["phone"],
          username: resources["username"],
          image: ''
        })
        this.imgSrc = resources["image"];
        let message: any = res["message"];
        this.LoadingProvider.CloseHttpServiceLoading()
      });
    // this.LoadingProvider.CloseHttpServiceLoading()
  }
  LogInForm = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.email]),
    phone: new FormControl('',
      [Validators.required, Validators.pattern("^[0-9]*$")]),
    username: new FormControl('',
      [Validators.required, Validators.pattern("^[\u0621-\u064A\u0660-\u0669a-zA-Z]*$")]),
    image: new FormControl('')
  })
  showPreview(event: any) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => this.imgSrc = e.target.result;
      reader.readAsDataURL(event.target.files[0]);
      this.selectedImage = event.target.files[0];
    }
    else {
      this.imgSrc = "assets/images/image-placeholder.jpg";
      this.selectedImage = null;
    }
  }
  checkemail(event) {
    this.typeingemail = true
  }
  checkuserphone(event) {
    this.typeingFirstName = true
  }
  checkusername(event) {
    this.typeingname = true
  }
  onSubmit(formData) {
    if (formData.valid) {
      this.LoadingProvider.ViewHttpServiceLoadingDiv()
      this.ApiService.Edit_data(formData.value, this.selectedImage).subscribe(
        res => {
          // //console.log(res);
          if (res["data"] != null) {
            // SessionData.SaveDataInSessionByLoginAndValidateSessionAndCheckNumber(res["data"]);
            this.toastr.success(res["message"]);
            // this.cookieService.set('lang', res.data.lang)
            // this.router.navigate(['/LoginComponent']).then(() => {
            //   // window.location.reload();
            // });
            this.LoadingProvider.CloseHttpServiceLoading()
          }
          else {
            this.toastr.error(res["message"]);
            this.LoadingProvider.CloseHttpServiceLoading()
          }
        })
    }
    else {
      if (localStorage.getItem('lang') == 'ar') {
        this.toastr.error("من فضلك قم بملئ البيانات بشكل صحيح");
      }
      else {
        this.toastr.error("please fill right data");
      }
    }
  }
}
