export class SessionData {
  public static UserId: number;
  public static UserName: string;
  public static UserPhoneNumber: string;
  public static UserPictureToken: string;
  public static UserGender: string;
  public static UserType: string;
  public static DesignerAgreement: boolean;
  public static UserIsLoggedIn: boolean;
  public static Language: string = null;
  public static image: string;
  public static Email: string;

  public static SiteEmail: string;
  public static SitePhone: string;
  public static SiteFaceBook: string;
  public static SiteInsta: string;
  public static SiteSnap: string;
  public static SiteTwitter: string;
  public static SiteLogo: string;
  public static SiteAbout: string;
  public static Siteaddress: string;
  public static shipping_price: string;
  static SaveDataInSessionByLoginAndValidateSessionAndCheckNumber(Data) {
    localStorage.setItem('UserToken', Data.token);
    this.UserId = Data.id;
    this.UserName = Data.username;
    this.UserPhoneNumber = Data.phone;
    this.image = Data.image;
    this.Email = Data.email;
    if (Data.lang != null) {
      this.Language = Data.lang;
    }
    else {
      this.Language = 'ar';
    }
    this.UserIsLoggedIn = true;
  }

  static SaveDataInSession(Data) {
    localStorage.setItem('UserTokenn', Data.token);
    this.UserIsLoggedIn = false;
    this.UserId = null;
    this.UserName = null;
    this.UserPhoneNumber = null;
    this.image = null;
    this.Email = null;
  }
  static ClearDataInSessionByLogOut() {
    localStorage.removeItem('UserToken');
    this.UserIsLoggedIn = false;
  }
}
