import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2'
import { APiService } from './../../service/api.service';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { LoadingProvider } from './../../service/loading-provider';
import { SessionData } from './../../service/seisson-data';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit {

  @Input() ProductDetails: any;
  private readonly notifier: NotifierService;

  constructor(
    private ApiService: APiService,
    private router: Router,
    private toastr: ToastrService,
    private LoadingProvider: LoadingProvider
  ) {
    // this.notifier = notifierService;
  }
  public sessionData = SessionData;
  imgSrc: any;
  ngOnInit(): void {
    this.imgSrc = this.ProductDetails.image
  }

  productFav(id, isFav) {
    if(this.sessionData.UserIsLoggedIn == true) {
      this.LoadingProvider.ViewHttpServiceLoadingDiv()

      this.ApiService.add_delete_wishList(id).subscribe(
        res => {
          // //console.log(res);

          if (res.status == 1) {
            // SessionData.SaveDataInSession(res["data"]);
            if (isFav == true) {
              this.toastr.success(res['message']);
            }
            else {
              this.toastr.success(res['message']);
            }
            // this.cookieService.set('lang', res.data.lang)
            this.ngOnInit();
            // this.router.navigate(['/HomeComponent']).then(() => {
            //   // window.location.reload();
            // });
            this.LoadingProvider.CloseHttpServiceLoading()
          }
          else {
            this.toastr.success(res['message']);
            this.LoadingProvider.CloseHttpServiceLoading()
          }
        });
      // this.LoadingProvider.CloseHttpServiceLoading()
    }
    else {
      this.router.navigate(['/LoginComponent']).then(() => {
        // window.location.reload();
      });
    }

  }
  changImage(imgSrc) {
    this.imgSrc = imgSrc;
  }
  addTocart(id) {
    if (this.sessionData.UserIsLoggedIn == true) {
      this.LoadingProvider.ViewHttpServiceLoadingDiv()

      this.ApiService.add_Cart(id).subscribe(
        res => {
          // //console.log(res);

          if (res.status == 1) {

            this.toastr.success(res['message']);

            this.ngOnInit();
            this.LoadingProvider.CloseHttpServiceLoading()
          }
          else {
            this.toastr.success(res['message']);
            this.LoadingProvider.CloseHttpServiceLoading()
          }
        });
    }
    else {
      this.router.navigate(['/LoginComponent']).then(() => {
      });
    }
  }
}
