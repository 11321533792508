import { AuthenticationGuard } from './service/authentication-guard';
import { ProductCardComponent } from './shared/product-card/product-card.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgbModule, NgbPaginationModule, NgbAlertModule } from "@ng-bootstrap/ng-bootstrap";
import { CookieService } from 'ngx-cookie-service';
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './Components/header/header.component';
import { FooterComponent } from './Components/footer/footer.component';
import { ServiceSerivceComponent } from './Components/service-serivce/service-serivce.component';
import { LoginComponent } from './Components/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ResetPasswordComponent } from './Components/reset-password/reset-password.component';
import { RegisterPageComponent } from './Components/register-page/register-page.component';
import { ForgetPasswordComponent } from './Components/forget-password/forget-password.component';
import { WriteCodeComponent } from './Components/write-code/write-code.component';
import { AboutUsComponent } from './Components/about-us/about-us.component';
import { NewsComponent } from './Components/news/news.component';
import { HomeComponent } from './Components/home/home.component';
import { ClientsComponent } from './Components/clients/clients.component';
import { MyAccountComponent } from './Components/my-account/my-account.component';
import { MyInfoComponent } from './Components/my-info/my-info.component';
import { MyaddressComponent } from './Components/myaddress/myaddress.component';
import { CartComponent } from './Components/cart/cart.component';
import { FavoirtComponent } from './Components/favoirt/favoirt.component';

import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { NewsDetailesComponent } from './Components/news-detailes/news-detailes.component';
import { ProductDetailesComponent } from './Components/product-detailes/product-detailes.component';
import { TabsModule } from 'ngx-tabset';
import { ProductsComponent } from './Components/products/products.component';

import { LoadingProvider } from './service/loading-provider';
import { ContactusComponent } from './Components/contactus/contactus.component';
import { ChangepassworrdComponent } from './Components/changepassworrd/changepassworrd.component';
import { BarRatingModule } from "ngx-bar-rating";

import { NgWizardModule, NgWizardConfig, THEME } from 'ng-wizard';
import { OrdersComponent } from './Components/orders/orders.component';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from '../environments/environment';
import { ToastrModule } from 'ngx-toastr';

import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const ngWizardConfig: NgWizardConfig = {
  theme: THEME.default
};

const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
      distance: 12
    },
    vertical: {
      position: 'top',
      distance: 12,
      gap: 10
    }
  },
  theme: 'uifort',
  behaviour: {
    autoHide: 5000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease'
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 300,
      easing: 'ease'
    },
    overlap: 150
  }
};

@NgModule({
  declarations: [ AppComponent, HeaderComponent,FooterComponent, ServiceSerivceComponent, LoginComponent, ResetPasswordComponent,
    RegisterPageComponent, ForgetPasswordComponent, WriteCodeComponent, AboutUsComponent, NewsComponent,
    HomeComponent, ClientsComponent, MyAccountComponent, MyInfoComponent, MyaddressComponent, CartComponent,
    FavoirtComponent, ProductCardComponent,
    NewsDetailesComponent,
    ProductDetailesComponent,
    ProductsComponent,
    ContactusComponent,
    ChangepassworrdComponent,
    OrdersComponent,
  ],
  imports: [
    BarRatingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    TabsModule,
    ToastrModule.forRoot({
      timeOut: 2000,
      preventDuplicates: true,
      positionClass: 'toast-bottom-right'
      // }
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgWizardModule.forRoot(ngWizardConfig),
    NotifierModule.withConfig(customNotifierOptions),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features
  ],
  providers: [CookieService, LoadingProvider, AuthenticationGuard
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
